.ant-modal-content {
    padding: 40px 30px !important;
}

.ant-form {
    animation-duration: 0s !important;
}

.ant-form > .ant-form-item {
    margin-bottom: 15px !important;
}

/* .ant-form > .ant-form-item:last-of-type{
    margin-bottom: 0px !important;
} */

.ant-form > .ant-form-item.FormItem_button {
    margin-bottom: 0px !important;
}

.ant-form > .ant-form-item:first-child .ant-form-item-control-input {
    margin-top: 0;
}

.ant-modal .ant-modal-footer {
    margin-top: 0 !important;
}

.ant-form-item .ant-form-item-explain {
    text-align: left !important;
    margin: 4px 0px 10px 0px;
}

h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 0;
}

.ant-form-vertical .ant-form-item-label {
    padding: 4px 0 0 0;
}

.ant-table {
    font-family: var(--font2) !important;

    ::-webkit-scrollbar {
        height: 20px;
        width: 20px;
    }
  
    ::-webkit-scrollbar-track {
        background: transparent;
    }
  
    ::-webkit-scrollbar-thumb {
        background: var(--bluish-grey-3);
        border-radius: 90px; 
        border: 5px solid white;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--bluish-grey);
    } 
}

.ant-select {
    font-family: var(--font2);
}

.ant-select-multiple {
    font-size: 16px;
}

.ant-select-selector {
    border: 1px solid var(--light-grey-3) !important;
    border-radius: 50vh !important;
    padding-inline: 10px !important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-1k979oh).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color:  var(--grey) !important;
    box-shadow: none;
}

.ant-select-selection-search {
    margin-inline-start: 0px !important;
}

.ant-select-selection-placeholder {
    color: var(--bluish-grey) !important;
}

.ant-switch-checked {
    background: var(--red) !important;
}

.ant-switch.ant-switch-checked .ant-switch-loading-icon {
    color: var(--red) !important;
}