.clientDashboard {
  display:flex;
  flex-direction: column;
  min-height: 100vh;
}

.clientDashboard button:focus {
  outline:none;
}

button.notifyClaim {
  font-size:1rem;
  font-weight: 600;
  padding-top:11px;
  padding-bottom:11px;
  padding-right:19px;
  padding-left:16px;
  letter-spacing: 0px;
  border-radius: 3px;
  background-color: white;
  border:none;
}

button.notifyClaim svg {
  margin-right:25px;
  margin-left: 5px;
}

.showPointerCursor {
  cursor: pointer;
}

.breachNotification {
  max-width: 932px;
  position:relative;
}

.websiteSecurity {
  max-width: 1327px;
  position:relative;
}

.websiteSecurity button {
  cursor: pointer;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  padding: 70px 20px 0px 20px;
  font-family: var(--font2);
  background-color: var(--application-background-color);
}

.dashboard-body-container {
  margin: 0 auto;
  max-width: var(--section-width);
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.section .stolenPasswords p {
  max-width: 693px;
}

.section .tabTitles {
  margin-top: 30px;
}

.section .tabTitles div{
  display: inline-block;
  margin-right: 33px;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #D6D6D6;
}

.section .tabTitles .active {
  color: #5c5c5c;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 2px solid #5c5c5c;
}

.websiteSecurity .monitoredDomains, .breachNotification .monitoredEmails {
  list-style: none;
  padding-left:0;
  max-width: 932px;
}

.websiteSecurity .tabs, .breachNotification .tabs {
  margin-top:25px;
}

.websiteSecurity .monitoredDomain {
  display: flex;
  justify-content: space-between;
  padding: 16px 22px;
  color: white;
  font-size: 18px;
  font-weight: 300;
  border-radius:6px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
-moz-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
}

.websiteSecurity .monitoredDomain .title {
  font-weight: 700;
  min-width: 200px;
}

.breachNotification ul.monitoredEmails {
  margin-top: 0 !important;
}

.breachNotification .monitoredEmail.active .title {
  font-weight: 700;
  min-width: 300px;
}

.websiteSecurity .mediumRisk {
  background-color: #FFBC38;
}

.websiteSecurity .lowRisk {
  background-color: #8CD45D;
}

.websiteSecurity .highRisk {
  background-color: #FF6565;
}

.websiteSecurity .notYet {
  background-color: #D6D6D6;
}

.websiteSecurity .pending {
  background-color: #D6D6D6;
}

.websiteSecurity .lastScanned, .websiteSecurity .removeButton {
  padding: 0 5px;
}

.websiteSecurity .notYet .viewResults {
  display:none;
}

.websiteSecurity .notYet {
  color: #2D2D2D;
}

.websiteSecurity button.viewResults {
  color: #282828;
  background-color: white;
  border-radius: 3px;
  border:none;
  padding:3px 15px;
  margin-top: -3px;
  font-weight: 700;
  width: 135px;
}

.websiteSecurity button.viewResults:focus {
  outline: none;
}

.websiteSecurity .monitoredDomain .risk {
  min-width: 150px;
  text-align:Center;
}

.websiteSecurity button.removeButton {
  background-color: transparent;
  border: none;
  color: #2D2D2D;
}

.websiteSecurity .addMoreButton, .breachNotification .addMoreButton {
  font-size: 18px;
  font-weight: 300;
  background-color: transparent;
  border: none;
  width: 130px;
  display:block;
  margin: 0 auto;
  color: #444444;
}

.websiteSecurity .addMoreButton:focus, .breachNotification .addMoreButton:focus {
  outline: none;
}

.websiteSecurity .addMoreButton svg, .breachNotification .addMoreButton svg {
  transform: rotate(45deg);
  margin-right: 15px;
}

.websiteSecurity h2, .phishingSimulator h2 {
  margin-top: 40px;
  color: #5c5c5c;
  font-weight: 700;
  font-size: 21px;
}

.phishingSummary .card-title svg {
  color: var(--red);
  margin-left: 5px;
  margin-bottom: 5px;
}

.phishingSimulator .card {
  -webkit-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
}

.phishingSimulator .card-text {
  font-size: 28px;
  font-weight: 800;
}

.phishingSimulator .infoButton {
  border:none;
  background: transparent;
}

button.bigger, button.bigger-only-icon, button.bigger-right-icon, button.very-big {
  font-size: 18px;
  border-radius: 3px;
  border:none;
  padding: 8px 15px;
  font-weight: 700;
  display:inline-block;
  margin: 10px;
}

button.very-big {
  height: 100px;
  width: 150px;
}

button.bigger svg, button.very-big svg {
  margin-right: 15px;
}

button.bigger-right-icon svg {
  margin-left: 15px;
}

button.link {
  border-radius: 15px;
  width: auto;
  border:none;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px; 
  padding-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  background-color: rgb(202, 240, 255);
}

.phishingSimulator button.removeButton {
  color: #282828;
  background-color: lightgrey;
}

.popUpAddCampaign button.campaignType {
  font-size: 12px;
  color: white;
  border-radius: 3px;
  border:none;
  padding: 0;
  font-weight: 700;
  margin: 10px;
  width: 90px;
  height: 90px;
  vertical-align:top;
  background-color:transparent;
}

.popUpAddCampaign .separator {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0px 50px;
}
.popUpAddCampaign .separator::before,
.popUpAddCampaign .separator::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.35);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
}

.popUpAddCampaign .comingSoon {
  font-size: 12px;
  color: black;
  border:none;
  padding: 0;
  font-weight: 700;
  margin: 20px;
  width: 150px;
  height: 150px;
  background-color:transparent;
}

.popUpAddCampaign .targets {
  padding: 15px 15px;
  margin: 20px;
  width: 150px;
  height: 150px;
  font-weight: 700;
}

.popUpAddCampaign .btn#back {
  background-color: #2d7ff986;
  padding: 11px 15px;
  position: absolute;
  left:0;
}

.popUpAddCampaign button.testAgain {
  margin-right: 50px;
}

.popUpAddCampaign .btn#finish {
  background-color: #8CD45D;
  color: white;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.8px;
  padding: 10px 20px;
}
.popUpAddCampaign .btn#finish svg {
  margin-left: 10px;
}

.phishingSimulator {
position:relative;
}

.clientDashboard .websiteResult h1 svg {
  margin-left: -55px;
  margin-right: 25px;
}

.websiteResult .titleRight {
  color: #A1A1A1;
  font-size: 17px;
  font-weight: 300;
  display:inline-block;
  width:40%;
  text-align: right;
}

.websiteResult h1 {
  width:60%;
  display:inline-block;
}

.websiteResult .titleRight .runScanButton, .breachNotification .runScanButton, .phishingSimulator .runScanButton {
  background-color: #D9D9D9;
  border: none;
  padding: 6px 15px;
  border-radius: 3px;
  color: #404040;
  margin-left: 10px;
}

.breachNotification .runScanButton, .phishingSimulator .runScanButton {
  padding: 8px 15px;
  color: white;
  font-size: 18px;
}

.breachNotification .runScanButton svg, .phishingSimulator .runScanButton svg {
font-size:24px;
}

.titleRight .runScanButton svg, .breachNotification .runScanButton svg, .phishingSimulator .runScanButton svg{
  margin-right: 17px;
}

.section .websiteResult {
  padding-left:44px;
  margin-top:5px;
  letter-spacing: 0.5px;
  max-width: 980px;
}

.section .websiteResult h1{
  font-size: 30px;
  color: #373737;
  font-weight: 600;
}

.section .websiteResult ul, .section .breachNotification ul, .section .websiteSecurity ul.faq,
.section .breachNotification ul.faq, .section .phishingSimulator ul, .section .phishingSimulator ul.faq, .whitelisting ul.faq{
  list-style: none;
  padding-left:0;
  margin-top:30px;
}

.section .phishingSimulator {
  max-width: 950px;
}

.section li .threatTitle, .section .websiteSecurity li .faqTitle, 
.section .breachNotification li .faqTitle, .section .phishingSimulator li .faqTitle, .whitelisting li .faqTitle {
  display: flex;
  padding: 16px 22px;
  color: #2C2C2C;
  font-size: 18px;
  font-weight: 800;
  border-top-right-radius:6px;
  border-top-left-radius: 6px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.breachNotification .breachTitle .title {
  min-width: 300px;
}

.phishingSimulator div.status {
  color: #282828;
  background-color: lightgrey;
  border-radius: 3px;
  border:none;
  font-weight: 700;
  text-align: center;
}

.section li .breachTitle {
  display: flex;
  padding: 16px 22px;
  color: #2C2C2C;
  font-size: 18px;
  border-top-right-radius:6px;
  border-top-left-radius: 6px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.section li.active .breachTitle {
  font-weight: 800;
}

.section .websiteResult li, .section .breachNotification .monitoredEmail, .section .faq > li, .section .faq > li {
  margin-bottom:20px;
  border-radius:6px;
  -webkit-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
}


.websiteResult .threats > li .threatDetail, .faq > li .faqDetail, .breachNotification .breachDetail {
  display:none;
}

.websiteResult .threats > li.active .threatDetail, .faq > li.active .faqDetail, .breachNotification li.active .breachDetail {
  display: block;
  padding: 16px 22px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.faq > li.active .faqDetail img{
  width: 100%;
  margin: 20px 0;
}
.faq > li.active .faqDetail h2 {
  font-size: 26px;
}

.faq > li.active .faqDetail p {
  font-size: 16px;
}

.websiteResult .threats li.active .threatTitle, .faq li.active .faqTitle {
  background-color:rgba(63,133,248, 0.13);
}

.websiteResult .threatTitle .title svg {
  color: var(--red);
  margin-left: 20px;
  font-size: 20px;
}

.websiteResult .threatTitle > div.rightPart, .breachNotification .breachTitle > div.rightPart {
  display: flex;
}

.websiteResult .threatTitle > div > svg {
  font-size: 30px;
  color: #2C2C2C;
  margin-left: 40px;
  margin-right:30px;
}

.websiteResult .threatTitle .riskTag {
  font-weight: 400;
  border-radius: 6px;
  padding: 3px;
  width: 190px;
  text-align: center;
  font-size: 18px;
}

.websiteResult .threatDetail .vulnerabilities, .websiteResult .threatDetail .protections {
  width:50%;
  min-width:300px;
  display:inline-block;
  vertical-align: top;
}

.websiteResult .threatDetail ul {
  margin-top:5px;
  font-size:18px;
  font-weight: 700;
  margin-left: 20px;
}

.websiteResult .threatDetail ul li {
  box-shadow: none;
  margin-left:46px;
  display: flex;
  font-weight: 300;
  font-size: 16px;
  margin-top: 25px;
}

.websiteResult .vulnerabilityList svg, .websiteResult .protectionList svg {
  font-size: 34px;
    vertical-align: middle;
    margin-right: 10px;
}

.websiteResult .threatDetail a.fixIt {
  color: white;
  background-color: var(--red);
  padding: 3px 8px;
  min-width:60px;
  font-weight: 700;
  font-size:12px;
  text-decoration: none;
  border-radius:2px;
  height: 22px;
  margin-top:15px;
}

.threatDetail .fixIt svg{
  font-size: 12px;
  display:inline-block;
}

table.breachList thead{
  border-bottom:1px solid #D8D8D8;
  margin-bottom:5px;
  color: var(--red);
  font-size: 16px;
}

table.breachList {
  margin-top: 30px;
  width:100%;
}

table.breachList td {
  padding: 5px 10px;
  min-width: 130px;
}
table.breachList tr td:last-child {
  min-width: 60px;
}

table.breachList tbody tr:hover {
background-color: #73BBFC;
}

table.breachList tbody tr td:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

table.breachList tr td:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width:100px;
}

.breachDetail .breaches {
  padding-right:20px;
  padding-bottom:10px;
}

.breachNotification li.active .breachDetail {
  padding-top: 0px;
}

.breachList .moreInfoButton {
  border:none;
  background: transparent;
  font-size: 12px;
}

.breachNotification .breachDetail .emailActionsWrapper {
text-align: right;
}
.breachDetail .updateButton, .breachDetail .deleteButton {
  border: none;
  background:transparent;
  color: #444444;
}

.setupAccountScreen {
  background-color:rgba(45,128,249);
  height:100%;
  width:100%;
  min-width: 100vw;
  min-height: 100vh;
  padding-bottom: 30px;
}

@media screen and (max-width: 870px) {
  .setupAccountScreen .mobileOnly {
    display: block !important;
  }
  .setupAccountScreen .notMobile {
    display: none !important;
  }
}

@media screen and (max-width: 550px) {

  .step .nameHolder {
    flex-wrap: wrap;
  }

  .step  div {
    margin-right: 0 !important;
  }
  .step .inputGroup {
    margin-top: 12px !important;
  }
  .step .inputGroup input {
    margin-top: 0 !important;
  }

  .step .passwordHolder {
    margin-top: 12px !important;
  }

  .step .passwordHolder .inputGroup {
    margin-top: -2px !important;
  }

  .step .passwordHolder .inputGroup:first-child {
    margin-right: 10px !important;
  }
}

@media screen and (min-width: 871px) {
  .setupAccountScreen .mobileOnly {
    display: none !important;
  }
  .setupAccountScreen .notMobile {
    display: flex !important;
  }
}

.setupAccountScreen .step {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.clientDashboard .orientation {
  font-size: 16px;
  width: 600px;
  margin-bottom: 30px;
}

#acceptTermsAndPrivacy {
  transform: scale(1.2, 1.2);
}

@media screen and (max-width: 550px){
  #acceptTermsAndPrivacy {
  -webkit-transform: scale(2.2,2.2);
  }
  .setupAccountScreen {
    background-color: white;
    background-image:none;
  }
}

.clientDashboard .orientation.tutorial .horizontalLine {
  left: 54px;
  transform: translate(70%,0);
  width: 30%;
}

.popUpAddCampaign .smile {
  margin-bottom: 30px;
}

.step .btn#add:focus {
  border:none;
  box-shadow: none;
  }
  
  .step .btn#back {
    background-color: #2d7ff986;
    padding: 11px 15px;
    position: absolute;
    left:0;
  }
  
  .step .btn#finish {
    background-color: #8CD45D;
    color: white;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.8px;
    padding: 10px 20px;
  }
  .step .btn#finish svg {
    margin-left: 10px;
  }
  
  .setupAccountScreen .step .ch {
    padding:0;
    }
  
  .setupAccountScreen .step .ch label {
    font-weight: 400 !important;
    margin-right: 30px !important;
  }
  
.step a {
  color: black;
}

.clientDashboard .green {
  background-color: #8CD45D;
  border: 1px solid #8CD45D;
}

.clientDashboard .red {
  background-color: #FF6565 ;
  border: 1px solid #FF6565;
}

.clientDashboard .orange {
  background-color: #FFBC38 ;
  border: 1px solid #FFBC38;
}

.bigPopUp .popUpAddCampaign {
  font-size: 20px;
}

.popUpContent.bigPopUp h2 {
  font-size: 30px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 40px;
}

.popUpContent.bigPopUp p {
  color: var(--bluish-grey);
}

.popUpContent .noteText {
  margin-bottom: 20px;
  font-size: 14px;
}

.popUpTextWithoutButton img {
 width:100%;
}

.popUpTextWithoutButton h2 {
  color: var(--red);
  font-weight: 700;
}

input.generatePassword {
  max-width: 350px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.inputWithIcon {
  position: relative;
  white-space: nowrap;
}

.clientDashboard .inputWithIcon {
  display:inline;
}

.inputWithIcon > svg {
  background-color:white;
  font-size: 22px;
  position: relative;
  top: 3px;
  right: 36px;
  cursor: pointer;
}

.inputWithIcon.passwordLength svg{
  font-size: 16px;
}

.passwordLength {
margin-top: 20px;
margin-bottom: 20px;
}

.passwordLength select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding: 0 8px;
  text-align: center;
  margin-left: 20px;
  border-radius: 3px;
}

.passwordLength .buttonSort {
  padding: 0 5px;
  font-size: 16px;
  line-height:21px;
  vertical-align: bottom;
  background-color: transparent;
  border:  1px solid transparent;
  border-left: none;
  color: #838383;
}

.selectWithIcon {
  display:inline-block;
  white-space: nowrap;
}

.nonAlphanumeric {
  margin-left: 20px;
  transform: scale(1.5);
}

#passwordLength {
  width: 120px;
}

.selectWithIcon button {
  margin-left: -21px;
  pointer-events: none;
}

.inputWithIcon .syncing {
  color: #FFBC38;
  -webkit-animation: rotating .5s infinite; /* Safari 4.0 - 8.0 */
  animation: rotating .5s infinite;
}
@-webkit-keyframes rotating {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}

/* Standard syntax */
@keyframes rotating {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}

.clientDashboard .pagination {
  margin-top:35px;
  text-align:center;
  display:block;
  font-size:18px;
  max-width: 971px;
  width:100%;
}

.manageEmailList {
  color: #484848;
  width:100%;
  max-width: 1168px;
  position: relative;
}

.pagination span {
  margin-right: 20px;
}

.pagination button {
  font-weight:600;
  font-size: 18px;
  padding: 10px;
  min-width: 49px;
  text-align: center;
  background-color: white;
  border-radius:2px;
  color: var(--red);
  border: 1px solid #E3E3E3;
}

.pagination button:focus {
  outline: none;
}

.pagination button.active {
  background-color: var(--red);
  color: white;
  border: 4px solid var(--red);
}

.manageEmailList .listWrapper ul {
  margin-top:30px;
  padding: 0;
  list-style: none;
  max-width: 971px;
  min-height: 410px;
}

.manageEmailList .listWrapper ul li {
  display: flex;
  border-bottom: 1px solid #D6D6D6;
}
.manageEmailList .listWrapper .email {
  overflow-wrap: break-word;
}

.manageEmailList .listWrapper ul li:not(.listHeader):hover {
 background-color: rgba(63, 133, 248, 0.16);
 color: var(--red);
}

.manageEmailList .listWrapper ul li:hover .actions svg{
  color: var(--red);
}

.manageEmailList .listWrapper li div {
  padding:21px 0px 21px 15px;
}

.listWrapper li.listHeader div {
  padding-bottom: 15px;
  color: #9E9E9E;
  font-size: 14px;
}

.manageEmailList .listWrapper .fullName {
  max-width: 235px;
  min-width: 100px;
  width:100%;
}
.manageEmailList .listWrapper .email {
  width:100%;
  max-width: 300px;
  min-width: 130px;
}

.manageEmailList .listWrapper .includeIn {
  width:100%;
  min-width: 80px;
  max-width: 120px;
}

.manageEmailList .listWrapper .actions {
  max-width: 130px;
  width:100%;
  font-size: 17px;
}
.listWrapper .actions button {
  background-color: transparent;
  border: none;
}

.manageEmailList button.addUser {
  border: none;
  background-color: #FFBC38;
  color: white;
  padding: 10px 30px 10px 20px;
  font-size: 18px;
  border-radius: 3px;
}

.manageEmailList button.addUser svg {
  font-size: 21px;
  margin-right: 20px;
}

.boxContent{
  margin-bottom: 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  padding: 24px 28px;
  margin-top: 26px;
  width:100%;
  max-width:693px;
  min-height:122px;
  display:flex;
  flex-direction: row;
  flex-wrap: no-wrap;
}

.boxContent > svg {
  color: hsl(217, 93%, 61%);
  font-size: 30px;
}

.boxContent.bigIcon > svg
{
  font-size: 70px;
}

.boxContent .boxText{
  padding: 10px 25px;
  padding-top: 0px;
}

.boxContent .boxText h3{
  color: #3f85f8;
  font-size: 26px;
  font-weight: 900;
  line-height: 20px;
}

.boxContent .boxText p{
  max-width: 390px;
  width:100%;
  color: #393939;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 0;
  margin-top: 20px;
}

.boxContent .boxButton {
  width: 160px;
  height: 38px;
  border-radius: 3px;
  background: #3f85f8;
  margin-top: 18px;
  margin-left: 14px;
  padding: 8px 15px;
  padding-right: 5px;
  cursor: pointer;
  color: white;
  white-space: nowrap;
}
.boxContent .boxButton svg {
  margin-left: 10px;
  vertical-align: text-top;
  font-size: 20px;
}

.boxContent .boxButton span{
  width: 92.29px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  text-transform: uppercase;
}

.boxContent .number {
  min-width: 45px;
  height: 45px;
  font-size: 22px;
  border-radius: 50%;
  text-align: center;
  padding-top:5px;
}

.home .boxContent, .home .recommendationsContainer .boxContent h3, .home .recommendationsContainer .boxContent p {
  color: #F7B500;
}

.home .recommendationsContainer .boxContent:hover {
  background-color: #F7B500;
}

.home .recommendationsContainer .boxContent:hover .number {
  background-color: white !important;
  color: #F7B500 !important;
}

.home .recommendationsContainer .boxContent:hover .boxButton {
  background-color: white !important;
  color: #f7b500 !important;
}

.home .recommendationsContainer .boxContent:hover .boxButton span {
  background-color: white !important;
  color: black !important;
}

.home .recommendationsContainer .boxContent:hover h3 {
  color: white !important;
}

.home .recommendationsContainer .boxContent:hover p {
  color: white !important;
}

.home .recommendationsContainer .boxButton {
  width: auto;
}

.home .recommendationsContainer .boxButton svg{
  margin-top:3px;
}

.home .recommendationsContainer .boxButton span, .home .recommendationsContainer .boxButton svg {
  font-size: 14px;
  text-transform: capitalize;
}

.home .boxContent {
  max-width: 785px;
}

.home .boxContent h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.home .boxContent p {
  margin-top:0;
  max-width: 500px;
  font-size: 14px;
  font-weight: 400;
}


.bigBoxContent{
  width:100%;
  max-width: 817px;
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0,0,0,0.2);
  margin-top: 30px;
  padding: 20px 26px;
}

.bigBoxContent h2{
  color: #393939;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: -15px;
}

.bigBoxContent p{
  max-width: 740px;
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.mobileGreyBackground {
  background-color: rgba(0,0,0,0.52);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display:none;
}

.bigBoxContent .imagesContainer {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px 5px;
}

.bigBoxContent .imagesContainer > div {
  margin-right: 10px;
}

.bigBoxContent .imagesContainer > div > div{
  width: 155px;
  color: #292929;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 18px;
  letter-spacing: 0.2px;
}

.bigBoxContent .imagesContainer h4{
  width: 190px;
  color: #292929;
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  margin-top: 40px;
}

.pendingSweep {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.pendingSweep:before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.pendingSweep:hover, .pendingSweep:focus, .pendingSweep:active {
  color: white;
}
.pendingSweep:hover:before, .pendingSweep:focus:before, .pendingSweep:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.boxExample{
  width:100%;
  max-width: 746px;
  height: 287px;
  border-radius: 7px;
  background: #3f85f8;
  padding: 10px 20px;
  margin-top: 30px;
}

.boxExample h2{
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
}

.examplesContainer{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.examplesContainer > div{
  margin-top: 45px;
  text-align: center;
}

.boxExample .examplesContainer svg {
  color: #ffffff;
  font-size: 34px;
}

.boxExample .examplesContainer p {
  width: 189px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
}

.longArrowRight {
  padding:15px;
  margin-top:110px !important;
}

.longArrowRight svg{
  font-size:20px;
}

.orangeBg {
  background-color: #F7B500 !important;
  color: white !important;
}

.greyBg {
  background: #4F4F4F;
  color: white;
}

.greyBg:disabled {
  opacity: 0.4;
  cursor: default;
}

.home .protectLabel {
  margin-top: 78px;
 width: 140px;
 font-size: 24px;
 font-weight: 900;
 text-transform: uppercase;
 padding: 2px 15px;
 letter-spacing: 0.8px;
 margin-left:-10px;
 margin-bottom:25px;
}

.home .protectTitle {
  font-size: 21px;
  font-weight:600;
  margin-left:-10px;
}

.home .attacksContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-left: 35px;
  justify-content: space-between;
  width:100%;
  max-width: 820px;
}
.home .attackWrapper {
  position:relative;
  width: 148px;
  height: 150px;
  border-radius: 6px;
  text-align: center;
  font-size: 21px;
  cursor: pointer;
  margin-bottom: 20px;
}

.hasShadow {
  -webkit-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  -moz-box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
  box-shadow: 0px 2px 13px -1px rgba(0,0,0,0.19);
}

.home .attackWrapper .attackText{
  margin-top: 18px;
  line-height: 24px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.home .attackWrapper.comingSoon .attackText {
  color: #3f85f8;
  margin-top: 24px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}
.home .attackWrapper.comingSoon {
 background: #f1f1f1;
 opacity: 0.6;
}

.home .recommendationsContainer {
  min-height: 200px;
}

.home .attackWrapper.comingSoon svg {
  color: #3f85f8;
  font-size: 40px;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.home .attackWrapper svg {
  font-size: 30px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.home .attackWrapper.comingSoon .labelText {
  width:100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 2px 0px;
  font-weight: 900;
  font-size: 14px;
  text-transform: uppercase;
  position:absolute;
  top:0;
}

.websiteSecurity .runScanButton {
  width: 190px;
}

.clientDashboard .inputWithIcon {
  width:100%;
  max-width: 280px;
}

.inputWithIcon select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}

.clientDashboard .inputWithIcon svg {
  pointer-events: none;
}

.clientDashboard .inputWithIcon.iconButton svg {
  pointer-events: all;
}

.websiteResultsWrapper {
  margin-top: 54px;
  border-radius: 3px;
}

.websiteResultsWrapper .body {
  display:flex;
  flex-direction: row;
  padding: 70px 50px;
}

.websiteResultsWrapper .whiteSpace {
    
}

.websiteResultsWrapper .header {
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 20px;
  display:flex;
  justify-content: space-between;
}

.websiteResultsWrapper .label {
  font-size: 16px;
  max-width: 180px;
  width:100%;
  line-height: 22px;
  letter-spacing: 0.4px;
}

.websiteResultsWrapper .title {
  word-break: break-all;
  font-size: 24px;
  font-weight: 900;
  padding-top:8px;
  width:100%;
  letter-spacing: 1px;
}

.websiteResultsWrapper .scan .label {
  max-width: 80px;
}

.websiteResultsWrapper .domain {
  max-width: 275px;
  width:100%;
  padding-right:10px;
}

.headerLeftPart, .headerRightPart {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  max-width: 545px;
}

.websiteResultsWrapper .reportButton svg{
  margin-right: 15px;
  font-size: 24px;
}

.websiteResultsWrapper .reportButton {
  font-size: 16px;
  text-transform: uppercase;
  padding:12px 15px;
  width: auto;
  font-weight: 700;
  max-width: 248px;
  border-radius: 3px;
  transition-duration: 0.3s;

}

.websiteResultsWrapper .reportButton:hover {
 background: azure;
 transition-duration: 0.3s;
 transform: translateY(-5px)
 

}

.websiteResultsWrapper .buttonWrapper {
  padding: 20px 0px;
  max-width: 250px;
  width:100%;
  align-self: flex-start;

}

.websiteResultsWrapper .buttonWrapper.labeled {
  position:relative;
  border: 2px solid #FFB300;
  border-radius: 3px;
  max-width:280px;
  width:100%;
  height: 77px;
  margin-top: 8px;
  padding: 12px;
}

.websiteResultsWrapper .buttonWrapper.labeled span{
  position: absolute;
  top:-13px;
  left:43px;
  color: #FFB300;
  font-size:14px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 5px;
}

.dual {
  display: inline-block;
  margin-right: 30px;
  position: 'relative';
  z-index:1000;
}

.dual .scanResultBox {
  margin-left: 0;
  margin-right: 0;
}

.scanDuration {
  font-size: 20px;
  text-align: center;
  width:100%;
  max-width: 450px;
  margin-left:auto;
  margin-right:auto;
}

@media screen and (-ms-high-contrast: none) {
  .clientDashboard .popUpContent.tutorial {
    overflow-y: visible;
  }
}

@media screen and (max-width: 1320px) {

  .mobileGreyBackground.opened {
    display:block;
  }
  .section {
    width: 100%;
    height: 100%;
  }
  .monitoredDomain {
    flex-flow: column;
  }
}

@media screen and (max-width: 580px) {
  .breachTitle {
    flex-flow: column;
  }
  .threatTitle {
    flex-flow: column;
  }
  .breaches {
    overflow-x: auto;
  }

  .section .websiteResult {
    padding-left: 0;
  }
  .websiteResult h1, .websiteResult .titleRight {
    width: 100%;
  }

  .breachNotification .runScanButton span{
    display:none;
  }

  .breachNotification .runScanButton svg {
    margin-right: 0;
  }
  .boxContent {
    flex-wrap:wrap;
    justify-content: center;
  }

  .home .attacksContainer {
    margin-left:0;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.9) translate(-5%);
    padding: 20px 50px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1600px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.85) translate(-12%);
    padding: 20px 50px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.75) translate(-15%);
      padding: 20px 50px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.70) translate(-22%);
    padding: 10px 50px;
    
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.6) translate(-30%);
    padding: 0px 50px;
    
  }
    
    .websiteSecurity .websiteResultsWrapper .whiteSpace {
        
    margin-top: -30px;
    max-height: 450px;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.54) translate(-45%);
    padding: 0px 50px;
  }
    
    .websiteSecurity .websiteResultsWrapper .whiteSpace {
        
    margin-top: -70px;
    max-height: 400px;
    }
}

@media screen and (min-width: 950px) and (max-width: 1100px) {
  .websiteSecurity .websiteResultsWrapper .body:not(.scanIsRunning) {
    transform: scale(0.4) translate(-70%);
    padding: 0px 50px;
  }
    
.websiteSecurity .websiteResultsWrapper .whiteSpace {
        
    margin-top: -90px;
    max-height: 390px;
    }
}

.mobileShow {
  display: none;
}
@media screen and (max-width: 500px) {
  .mobileShow {
    display:block;
  }

  .breachNotification .mobileHide {
    display:none;
  }

  .breachNotification button .mobileSVG {
    margin-left: 0;
  }

  .breachNotification .mobile40W {
    width: 40% !important;
    max-width: 40% !important;
  }
  .breachNotification .mobile50W {
    width: 50% !important;
    max-width: 50% !important;
  }
  .breachNotification .mobile30W {
    width: 30% !important;
    max-width: 30% !important;
  }
  .breachNotification .mobile10mt {
    margin-top: 10px;
  }
} 