/*
=============================================
ROOT VARIABLES
=============================================
*/

:root {

    /*
    =============================================
    COLORING
    =============================================
    */

    --red-gradient: linear-gradient(180deg, #D05865 0%, #BC1224 100%);
    --light-red-gradient-1: linear-gradient(180deg, #DD818B 0%, #D05865 100%); 
    --light-red-gradient-2: linear-gradient(180deg, #EAA2A9 0%, #DD8891 100%);
    --light-red-gradient-3: linear-gradient(180deg, #F2CFD3 0%, #EAA2A9 100%);
    --orange-gradient: linear-gradient(180deg, #FFBE6E, #FFA019);
    --green-gradient: linear-gradient(180deg, #99CD4C, #78BC12);
    --light-green-gradient: linear-gradient(180deg, #B1D876BF 0%, #B1D876 100%);
    --bluish-grey-gradient: linear-gradient(180deg, #96A4B1, #798897);
    --red: #BC1224;
    --red-hover: #D05865;
    --red-active: #F8E7E9;
    --light-red: #F2CFD3;
    --light-red2: #DD8891;
    --light-red3: #D05865;
    --orange: #EF8E10;
    --light-orange: #FDEEDB;
    --green: #78BC12;
    --light-green: #EBF5DB;
    --light-green2:#B1D876;
    --black:#2E2E2E;
    --grey: #808080;
    --grey-2: #B8B8B8;
    --light-grey: #F2F4F6;
    --light-grey-2: #DDDDDD;
    --light-grey-3: #E7E7E7;
    --light-grey-4: #FBFBFB;
    --light-grey-5: #F7F7F7;
    --light-grey-6: #C5CDD5;
    --bluish-grey: #798897;
    --bluish-grey-2: #A4B3C1;
    --bluish-grey-3: #E6EAEE;
    --bluish-grey-4: #F8F9FA;
    --bluish-grey-hover: #9CA7B2;
    --white: #FFFFFF;
    --skull-white: #F9F9F9;
    --default-label-bluish-grey: #E2EAF0;
    --hr-color: #BFC9D3;
    --application-background-color: #E6EAEE;
    --table-row-hover-color: #F2F5F8;

    --partner-primary: var(--bluish-grey);
    --partner-primary-light: var(--bluish-grey-3);

    /*
    =============================================
    BOX SHADOWS
    =============================================
    */
    --section-box-shadow: 2px 2px 20px 0px rgba(121, 136, 151, 0.08);


    /*
    =============================================
    FONTS
    =============================================
    */

    --font1: 'Poppins', Helvetica, sans-serif;
    --font2: 'Karla', Arial, sans-serif;


    /*
    =============================================
    TEXT SIZES & LINE SPACING
    =============================================
    */

    --h1-size: 25px;       
    --h1-lineheight: 1.25;       
    --h1-weight: 500;           

    --h2-size: 20px;
    --h2-lineheight: 1.25;
    --h2-weight: 400;

    --h3-size: 16px;
    --h3-lineheight: 1.25;
    --h3-weight: 500;

    /* --h4-size: 0.95rem;
    --h4-lineheight: 1;
    --h4-weight: 600; */

    --ap-size: 16px;
    --ap-lineheight: 1.25;
    --ap-weight: 400;


    /*
    =============================================
    OTHERS
    =============================================
    */

    --btn-radius: 50vh;
    --section-radius: 0.3rem;
    --block-separation: 2rem;
    --transition-delay: 0.2s;
    --navbar-height: 100px;
    --section-width: 1050px;
    --section-width-xl: 1440px;
    --section-width-2xl: 1800px;

    /*
    =============================================
    BREAKPOINTS
    =============================================
    */

    --small-device: 576px;
    --medium-device: 768px;
}


/*
=============================================
TEXT TYPES
=============================================
*/

h1, h2, h3, h4, p, dl, ol, ul {
    margin: 0;
}

h1 {
    color: var(--black);
    font-family: var(--font1);
    font-size: var(--h1-size);
    font-weight: var(--h1-weight);
    line-height: var(--h1-lineheight);
}

h2 {
    color: var(--black);
    font-family: var(--font1);
    font-size: var(--h2-size);
    font-weight: var(--h2-weight);
    line-height: var(--h2-lineheight);
}

h3 {
    color: var(--black);
    font-family: var(--font2);
    font-size: var(--h3-size);
    font-weight: var(--h3-weight);
    line-height: var(--h3-lineheight);
}

h4 {
    color: var(--black);
    font-family: var(--font2);
    font-size: var(--h4-size);
    font-weight: var(--h4-weight);
    line-height: var(--h4-lineheight);
    margin-left: 1.5rem;
}

/* Added a:not([href]):not([tabindex]) to override _reboot.scss when <a> doesn't have href */
a, a:not([href]):not([tabindex]) {
    cursor: pointer;
    color: var(--red);
}

a:hover, a:not([href]):not([tabindex]):hover {
    color: var(--red-hover);
}


p {
    font-family: var(--font2);
    font-size: var(--ap-size);
    font-weight: var(--ap-weight);
    color: var(--black);
    line-height: var(--ap-lineheight);
}

p.outside,
a.outside {
    font-family: var(--font1);
    font-size: calc(var(--ap-size) - 0.1rem);
}

.swal2-icon span {
    font-size: 16px !important;
}

strong {
    font-weight: calc(var(--ap-weight) + 200);
}

/* Remove password toggle visibility button in Edge */
::-ms-reveal {
    display: none;
}




/*
=============================================
COLOR PICKERS
=============================================
*/

#red {
    background-color: var(--red);
}

#orange {
    background-color: var(--orange);
}

#green {
    background-color: var(--green);
}

#black {
    background-color: var(--black);
}

#grey {
    background-color: var(--grey);
}

#light-grey {
    background-color: var(--light-grey);
}

#skull-white {
    background-color: var(--skull-white);
}

#white {
    background-color: var(--white);
}


/*
=============================================
SCROLLBAR & SELECTION
=============================================
*/


::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: var(--bluish-grey);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--red);
}

::-moz-selection {
    /* Code for Firefox */
    color: var(--skull-white);
    background: var(--red);
}

/* ::selection {
    color: var(--skull-white);
    background: var(--bluish-grey);
} */


/*
=============================================
STRUCTURES
=============================================
*/

* {
    margin: 0;
    padding: 0;
}

html {
    background-color: var(--skull-white);
    margin: auto;
    width: 100%;
    height: 100%;
}

body {
    font-family: var(--font1);
}

/*
=============================================
BUTTONS
=============================================
*/

button {
    font-family: var(--font2);
    font-weight: 600;
    border-radius: var(--btn-radius);
    border-style: none;
    cursor: pointer;
}

/*
=============================================
INPUTS
=============================================
*/

input {
    box-shadow: none;
    border: none;
    border-width: 0px;
    font-family: var(--font2);
}

.form-control {
    padding: 5px 5px;
    border: none;
    border-bottom: 1px solid var(--light-grey-3);
    color: var(--black);
}

.form-control:focus {
    box-shadow: none;
    border-bottom: 1px solid var(--grey);
    color: var(--black);
}

/* Removed arrows from inputs type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Removed blue background fron inputs that are autocompleted by the navegator */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
}

/*
=============================================
IMAGES
=============================================
*/

.smilelottie {
    position: absolute;
    transform: translate(-50px, -65px);
    overflow: hidden;
}

.wings-section-background {
    background-image: url('../img/background/wings.svg');
    background-repeat: no-repeat;
    background-size: auto 120%;
    background-position-x: center;
}

.red-wings-section-background {
    background-image: url('../img/background/Wings-background-v2.svg');
    background-repeat: no-repeat;
    background-size: auto 65%;
    background-position: center;
    
}

.manLaptop-section-background {
    background-image: url('../img/background/manWithLaptop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}


.dataObfuscation {
    filter: brightness(0.25);
    overflow: hidden !important;
    z-index: 1999999997;
}

.dataObfuscation div{
    pointer-events:none;
}
/*
=============================================
GENERAL
=============================================
*/

.flex {
    display: flex;
}

.cursor-pointer {
    cursor: pointer;
}

/*
=============================================
APP
=============================================
*/

.dropdown {
    background-color: var(--white);
    border-radius: var(--btn-radius);
    color: var(--black);
}

.dropdown>option {
    background-color: var(--white);
    border-radius: var(--btn-radius);
    color: var(--black);
}

.dropdown>option:hover {
    background-color: var(--black);
    border-radius: var(--btn-radius);
    color: var(--white);
}

/*
=============================================
SWEETALERT2 STYLES
=============================================
*/
.swal2-container {
    z-index: 2000000005 !important;
}

.swal2-popup {
    width: 420px !important;
    padding: 50px !important;
}

.swal2-styled:focus {
    box-shadow: none !important;
}

.swal2-header {
    padding: 0px !important;
}

.swal2-icon.swal2-error {
    color: var(--red) !important;
    border-color: var(--red) !important;
}

.swal2-x-mark-line-left {
    background-color: var(--red) !important;
}

.swal2-x-mark-line-right {
    background-color: var(--red) !important;
}

.swal2-icon {
    margin: 0px 110px 50px 110px !important;
}

.swal2-title {
    color: var(--black) !important;
    font-size: 25px !important;
    font-weight: var(--h3-weight) !important;
    margin-bottom: 45px !important;
}

.swal2-content {
    padding: 0px !important;
    color: var(--black) !important;
    font-size: var(--ap-size) !important;
    font-weight: var(--h3-weight) !important;
}

.swal2-actions {
    margin-top: 50px !important;
}

.swal2-actions .swal2-styled.swal2-confirm {
    background-color: var(--red);
    width: 100%;
    border-radius: 50vh;
}

span.openTawk {
    color: var(--red);
    text-decoration: underline;
    cursor: pointer;
}

/*
=============================================
COOKIE BUTTON STYLES
=============================================
*/

#ot-sdk-btn-floating.ot-floating-button {
    display: none;
  }

  @media screen and (max-width: 769px) {
    #ot-sdk-btn-floating.ot-floating-button {
        display: none;
        
      }
}

.onetrust-pc-dark-filter {
    z-index: 2000000003 !important;
}

#ot-sdk-btn-floating.ot-floating-button::after {
    display: none !important;
}

#ot-sdk-btn-floating.ot-floating-button::before {
    display: none !important;
}

#ot-sdk-btn-floating.ot-floating-button.ot-pc-open {
    background-color: #2e2e2e !important;
}

.ot-floating-button__front {
    background: url("../img/cookie-red.svg") !important;
    background-size: 50% !important;
    background-color: #f9f9f9 !important;
    box-shadow: 0 0 0px 1px var(--red) inset, 0 0 1px 0px var(--red) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.ot-floating-button__front button {
    min-width: 0px !important;
}

.ot-floating-button__front button:focus {
    outline: none !important;
}

.ot-floating-button__back {
    transition: none !important;
    /* transform: rotateY(-180deg) !important; */
}

.ot-floating-button__back button {
    min-width: 0px !important;
}

.ot-floating-button__back button svg {
    display: none !important;
}

.ot-floating-button__back button:focus {
    outline: none !important;
}

#ot-sdk-btn-floating .ot-floating-button__back {
    background-color: #2e2e2e !important;
    /* transform: rotateY(-180deg) !important; */
    background-image: url("https://storage.googleapis.com/cyberguardian-assets/cookie.svg") !important;
    background-size: 50% !important;
    box-shadow: none !important;
}

/*
=============================================
HIDE GOOGLE RECAPTCHA BUTTON
=============================================
*/
.grecaptcha-badge {
    display: none !important;
}

/* Grey shadows for all boxes */
.shadow-grey {
    -webkit-box-shadow: 2px 2px 20px 0px rgba(121,136,151,0.08);
    box-shadow: 2px 2px 20px 0px rgba(121,136,151,0.08);
}

/* Animation experiments */
.spin-animation {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.logo-loader {
    width: 100px;
    margin: auto;
    animation-duration: 1.2s;
    animation-name: vibration;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
  
@keyframes vibration {
    0% {
        width: 100px;
        opacity: 0;
    }

    100% {
        width: 120px;
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.apexcharts-legend {
    justify-content: center !important;
    top: 0px !important;
}

.apexcharts-legend-marker {
    margin-right: 6px !important;
}
